$(document).ready(function () {
    matchHeight();
    handleCarousel();
    gallery();
    handleTable();
    handleQuicklink();

    $('body').addClass("loading");

    $(window).resize(function() {
        handleQuicklink();
    });
});

$(window).on("load", function() {

    handleFooter();
    $(window).trigger('resize');
});

function handleQuicklink() {
    var element = $('.quicklink');
    var tileWidth = element.outerWidth( true );
    var tileHeight = tileWidth;

    element.height(tileHeight)
}

function matchHeight() {
    $('article .block-title').matchHeight();
}

function handleCarousel() {
    $('.owl-carousel').owlCarousel({
        loop: true,
        dots: false,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause:true,
        margin: 20,
        navText : ["<i class=\"fas fa-angle-left\"></i>","<i class=\"fas fa-angle-right\"></i>"],
        responsive: {
            0: {
                items: 2
            },
            500:{
                items: 2
            },
            900:{
                items: 4
            }
        }
    })
}

function gallery() {

    $(".gallery").each(function(index,item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength >= 4) {
            $(item).addClass('more');
        }
    });
}

window.onload = function() {

    var placeholder = $("figure.figure-progressive");

    placeholder.each(function() {
        var small = this.querySelector('.img-small');

        //load small image and show it
        var img = new Image();
        img.src = small.src;
        img.onload = function () {
            small.classList.add('loaded');
        };

        //load large image
        var imgLarge = new Image();
        imgLarge.src = this.dataset.large;
        imgLarge.srcset = this.dataset.srcset;
        imgLarge.onload = function () {
            imgLarge.classList.add('loaded');
        };
        this.appendChild(imgLarge);

    });
};

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth= $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if((windowWidth >= 768) && ( contentHeight <= windowHeight)){
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

jQuery(function($) {

    $(".option-heading").click(function() {
        $(this).find(".arrow-up, .arrow-down").toggle();
    });

});

$(document).ready(function(){
    $('.owl-carousel').owlCarousel();
});

$('#navbar').on('show.bs.collapse', function () {
    $(this).toggleClass("menubackground");
});

$('#navbar').on('hidden.bs.collapse', function () {
    $(this).toggleClass("menubackground");
});
//
// $(window).scrPosition(function(a){
//     if($(window).outerWidth() < 1400) {
//         console.log(a);
//         if(a === 'up') {
//             $('#navbar').addClass('navbar-fixed-top');
//             $('#navbar').removeClass('d-none');
//         } else {
//             $('#navbar').removeClass('navbar-fixed-top');
//             $('#navbar').addClass('d-none');
//         }
//     }
// });

// $(document).ready(function(){
//     if($(window).scrollTop() < 10) {
//         $('#navbar').css('top', 0).addClass('navbarbackground');
//     }
// });

$(document).ready(function(){
    $("#contact-form").submit(function(){
        var input = $('#contactpersoon').val();
        $('#message').val($('#message').val() + ' | Contactpersoon: ' + input + ' | << Dit bericht is afkomstig van Qast.nl >>');
    });
});

$(document).ready(function() {
    if ($(window).width() > 991) {
        $('.navbar-nav .nav-item .nav-link').click(function () {
            if ($(this).parent('.nav-item').is('.show')) {
                window.location = $(this).attr('href');
            }
        });
    }
    else {
        $('.nav-link .dropdown-toggle').off('mouseover').off('mouseout');
        $('.nav-link').click(function () {
            if ($(this).attr('aria-expanded') === "true") {
                window.location = $(this).attr('href');
            }
        });
    }
});

$(document).ready(function() {
    if ($(window).width() > 1400) {
        $('#navbar').removeClass('.menubackground');
    }
});