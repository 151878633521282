/* Set the width of the side navigation to 250px and the left margin of the page content to 250px and add a black background color to body */
function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("page-wrapper").addClass('mobile-menu-active');
    document.body.style.backgroundColor = "rgba(0,0,0,0.9)";
}

/* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("page-wrapper").addClass('mobile-menu-active');
    document.body.style.backgroundColor = "white";
}

function showSubmenu() {
    // document.getElementById("mySidenav").style.width = "0";
    document.getElementById("#panel-menu").classList.addClass('sidenav-panel_open');
}

$("#panel-menu ul li.has-subitems").each(function(index){
    console.log(index);
});